import {
    Abstract
} from './Abstract';
import axios from "axios";

export class PaymentRequestInvoicePay extends Abstract {
    constructor(session) {
        super('payment_request_invoice_pay/PaymentRequestInvoicePay', session);
        this.id_payment_request = null;
        this.id_invoice_pay = null;
        this.id_bank_account = null;
        this.total = 0.00;
        this.status_invoice_pay = null;
        this.status = 1;
        this.exchange_rate = 0.00;
        this.id_policy = null;
        this.id_policy_voucher = null;
        this.id_expense_type = null;
        this.observations = null;
        this.currency = null;
        this.reconciliation_policy_date = null;

        /*Campos adicionales*/
        this.payment_request_reference = null;
        this.payment_request_date = null;
        this.payment_request_supplier = null;
        this.payment_request_key_name_account = null;
        this.payment_request_exchange_rate = null;
        this.payment_request_total = null;
        this.payment_request_reconciled_total = null;
        this.payment_request_not_conciled = null;

        /*Campos adicionales*/
        this.invoice_supplier = null;
        this.invoice_date = null;
        this.invoice_exchange_rate = null;
        this.invoice_total = null;
        this.invoice_reconciled_total = null;
        this.invoice_not_conciled = null;

        this.payments_details = [];

    }

    async payment () {
        if (this.id_payment_request == null || this.id_payment_request == 0 || this.id_payment_request == "") 
            throw "Seleccione un pago"
        let response = await axios.get(this.controller + '/payment/' + this.id_payment_request, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async invoice () {
        if (this.id_invoice_pay == null || this.id_invoice_pay == 0 || this.id_invoice_pay == "") 
            throw "Seleccione una factura"
        let response = await axios.get(this.controller + '/invoice/' + this.id_invoice_pay, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async post() {
        console.log("empresa: " + this.id_company);
        let response = await axios.post(this.controller + '/post', this, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async payments () {
        if (this.id_payment_request == null || this.id_payment_request == 0 || this.id_payment_request == "") 
            throw "Seleccione una solicitud de pago para recibir sus pagos"
        let response = await axios.get(this.controller + '/payments/' + this.id_payment_request, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }
}