import {
    Abstract
} from './Abstract';

import axios from 'axios';
var fileDownload = require('js-file-download');
export class DTPInvoice extends Abstract {
    constructor(session) {
        super('dtp_invoice/DTPInvoice', session);
        this.version = null;
        this.series = null;
        this.id_invoice = null;
        this.date = null;
        this.payment_form = null;
        this.payment_coditions = null;
        this.subtotal = 0.00;
        this.discount = 0.00;
        this.currency = 'MXN';
        this.exchange_rate = 1.00;
        this.voucher_type = null;
        this.export = null;
        this.payment_method = null;
        this.expedition_place = null;
        this.confirmation = null;
        this.global_information_periodicity = null;
        this.global_information_months = null;
        this.issuing_rfc = null;
        this.issuing_name = null;
        this.issuing_tax_regime = null;
        this.issuing_attribute = null;
        this.receiver_rfc = null;
        this.receiver_name = null;
        this.receiver_postal_code = null;
        this.receiver_tax_residence = null;
        this.receiver_num_reg_id_trib = null;
        this.receiver_tax_regime = null;
        this.receiver_invoice_use = null;
        this.taxes_total_detained = null;
        this.taxes_total_transferred = null;
        this.total = 0.00;
        this.is_certificated = false;
        this.uuid = null;
        this.certification_date = null;
        this.receiver_email = null;
        this.amount_paid = null;
        this.xml_filename = null;
        this.pdf_filename = null;
        this.is_sat_cancelable = "";
        this.is_sat_canceled = false;
        this.is_sat_valid = true;
        this.sat_status = null;
        this.sat_cancelation_status = null;
        this.status = 1;
        this.reconciled_total = 0.00;
        this.balance = 0.00;
        this.total_mxn = 0.00;
        this.id_supplier = null;
        this.concepts = [];
    }

    async validaSat() {
        let response = await axios.get(this.controller + '/validate/' + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async getConcepts() {
        let response = await axios.get(this.controller + '/concepts/' + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }


    async importDTP(invoices) {
        let response = await axios.post(this.controller + '/importdtp',invoices, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async validaSatList(invoices) {
        let response = await axios.post(this.controller + '/validate',invoices, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async list(params) {
        let response = await axios.post(this.controller + '/list', params, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async import() {
        let response = await axios.post(this.controller + '/import/mailbox', null, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async zip(invoices) {
        let response = await axios({
            url: this.controller + '/zip',
            method: 'POST',
            data: invoices,
            responseType: 'blob', // Important
          })
        fileDownload(response.data, 'Facturas.zip');
        return response.data;
    }

    async post() {
        let response = await axios.post(this.controller + '/post', this, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async provision() {
        let response = await axios.post(this.controller + '/provision', this, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async cancel_provision() {
        let response = await axios.post(this.controller + '/cancel_provision', this, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }
}

