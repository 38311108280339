<template>
    <div class="card">
        <Loader v-model="loading" />
        <ConfirmDeleteDialog @closed="deleteDialog = false" v-model="deleteDialog" @deleted="deleteDetail" /> 
        <Helper v-model="paymentRequestHelper" header="Buscar Solicitud de Pago" :headers="paymentRequestHeaders"
            :rows="paymentRequests" @selected="selectPaymentRequest" />
        <Helper v-model="invoicePayHelper" header="Buscar Factura Por Pagar" :headers="invoicePayHeaders"
            :rows="invoicePays" @selected="selectInvoicePay" />
        <Panel header="Solicitud de Pago">
            <div class="grid">
                <div class="col-6">
                    <Fieldset legend="Pagos">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-4">
                              <label># Pago</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="entity.id_payment_request" />
                                    <Button @click="paymentRequestHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                            <FormInputText wrapperClass="field col-4" v-model="entity.payment_request_reference" label="Nombre Pago Referencia"/>
                            <div class="field col-4">
                                <label>Fecha Pago</label>
                                <Calendar dateFormat="dd/mm/yy" v-model="entity.payment_request_date" :showIcon="true"/>
                            </div>
                            <FormInputText v-if="!isHidden" wrapperClass="field col-4" v-model="entity.id_bank_account" label="Cuenta Bancaria"/>
                            <FormInputText wrapperClass="field col-4" v-model="entity.payment_request_key_name_account" label="Cuenta Bancaria"/>
                            <FormInputText wrapperClass="field col-4" v-model="entity.payment_request_supplier" label="Proveedor"/>
                            <FormInputNumber wrapperClass="field col-4" v-model="entity.payment_request_exchange_rate" label="Tipo de Cambio" mode="currency" :minFractionDigits="4" :maxFractionDigits="4"/>
                            <FormInputNumber wrapperClass="field col-4" v-model="entity.payment_request_total" label="Importe Total" mode="currency" :minFractionDigits="2" :maxFractionDigits="2"/>
                            <FormInputNumber wrapperClass="field col-4" v-model="entity.payment_request_reconciled_total" label="Conciliado MN" mode="currency" :minFractionDigits="2" :maxFractionDigits="2" />
                            <FormInputNumber wrapperClass="field col-4" v-model="entity.payment_request_not_conciled" label="X Conciliar MN" mode="currency" :minFractionDigits="2" :maxFractionDigits="2" />
                        </div>
                    </Fieldset>
                </div>
                <div class="col-6">
                    <Fieldset legend="Facturas">
                        <div class="p-fluid formgrid grid">
                              <div class="field col-4">
                              <label># Factura</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="entity.id_invoice_pay" />
                                    <Button @click="invoicePayHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                            <FormInputText wrapperClass="field col-4" v-model="entity.invoice_supplier" label="Nombre Proveedor"/>
                             <div class="field col-4">
                                <label>Fecha Factura</label>
                                <Calendar dateFormat="dd/mm/yy" v-model="entity.invoice_date" :showIcon="true"/>
                            </div>
                            <FormInputNumber wrapperClass="field col-4" v-model="entity.exchange_rate"  label="Tipo de Cambio" :minFractionDigits="2" :maxFractionDigits="2" mode="currency" />
                            <FormInputNumber wrapperClass="field col-4" v-model="entity.invoice_reconciled_total"  label="Conciliado MN" :minFractionDigits="2" :maxFractionDigits="2" mode="currency"/>
                            <FormInputNumber wrapperClass="field col-4" v-model="entity.invoice_not_conciled"  label="X Conciliar MN" :minFractionDigits="2" :maxFractionDigits="2" mode="currency" />
                            <FormInputNumber wrapperClass="field col-4" v-model="entity.total"  label="Conciliar" :minFractionDigits="2" :maxFractionDigits="2" mode="currency" />
                            <FormInputNumber wrapperClass="field col-4" v-model="entity.invoice_total"  label="Importe Total" :minFractionDigits="2" :maxFractionDigits="2" mode="currency" />
                            <div class="field col-4">
                                <label>Fecha Póliza Conciliación</label>
                                <Calendar dateFormat="dd/mm/yy" v-model="entity.reconciliation_policy_date" :showIcon="true"/>
                            </div>
                             <div class="field col-2">
                               <br>
                                <Button @click="save">Conciliar</Button>
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div class="col-12">
                    <Fieldset legend="Partidas">
                       <div class="p-fluid formgrid grid">
                            <div class="field col-12">
                                <BasicDatatable 
                                :rowaction="true"
                                :rowdelete="true" 
                                fontsize="small"
                                @deleted="confirmDelete"
                                :headers="paymentsHeaders" 
                                :rows="entity.payments_details" />
                            </div>
                        </div>
                    </Fieldset>
                </div> 
            </div>
    </Panel>
    </div>
</template>

<script>
import { Branch } from '../../../models/general/Branch';
import { Rule, fillObject, ErrorToast, HeaderGrid } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import Helper from '../../../components/general/HelperDialog.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import Session from '../../../mixins/sessionMixin';
import { PaymentRequest } from '../../../models/cxp/PaymentRequest';
import { PaymentRequestInvoicePay } from '../../../models/cxp/PaymentRequestInvoicePay'
import { DTPInvoice } from '../../../models/cxp/DTPInvoice';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import ConfirmDeleteDialog from '../../../components/general/DeleteDialog.vue';
// import DeleteDialogComponent from '../../../components/general/DeleteDialog.vue';
export default {
    mixins: [Session],
    props: {
        id: null,
        view: null,
    },
    data() {
        return {
            deleteDialog: false,
            newConcept: false,
            entity: null,
            entities: [],
            paymentRequests: [],
            invoicePays: [],
            isHidden: true,
            detailDialog: false,
            paymentRequestHeaders: [new HeaderGrid('Folio', 'id'), new HeaderGrid('Fecha', 'date'), new HeaderGrid('Proveedor', 'supplier_name'), new HeaderGrid('Moneda', 'id_currency'), new HeaderGrid('Total', 'total'), new HeaderGrid('Estatus', 'status')],
            invoicePayHeaders: [new HeaderGrid('#', 'id'), new HeaderGrid('Serie', 'series'), new HeaderGrid('Folio', 'id_invoice'), new HeaderGrid('Fecha', 'date'), new HeaderGrid('Proveedor', 'supplier_name'), new HeaderGrid('Moneda', 'id_currency'), new HeaderGrid('Total', 'total'), new HeaderGrid('Estatus', 'status')],
            paymentsHeaders: [new HeaderGrid('Folio', 'id'), new HeaderGrid('Folio Pago', 'id_payment_request'), new HeaderGrid('Folio Factura', 'id_invoice_pay'), new HeaderGrid('Cuenta Bancaria', 'id_bank_account'), new HeaderGrid('Tipo Cambio', 'exchange_rate', { type: 'currency'}),new HeaderGrid('Moneda', 'currency'), new HeaderGrid('Total', 'total', { type: 'currency'})],
          rules: [
                new Rule({ name: 'id_bank_account' }),
                new Rule({ name: 'date_request' }),
                new Rule({ name: 'date_pay' }),
                new Rule({ name: 'id_supplier' }),
                new Rule({ name: 'supplier_name' }),
                new Rule({ name: 'total' }),
                new Rule({ name: 'invoices' }),
                new Rule({ name: 'concept_name' }),
                new Rule({ name: 'id_currency' }),
                new Rule({ name: 'exchange_rate' }),
                new Rule({ name: 'type_of_request' }),
                new Rule({ name: 'id_nature' }),
            ],
            rules_detail: [
            ],
            detailDelete:  new PaymentRequestInvoicePay(),
            branch: new Branch(this.session),
            validate: {
                valid: false,
                validations: {
                },
            },
            validate_detail: {
                valid: false,
                validations: {
                },
            },
            loading: false,
            paymentRequestHelper: {
                visible: false,
            },
            invoicePayHelper: {
                visible: false,
            },
        };
    },
    components: { FormInputNumber, Loader, Helper, FormInputText, BasicDatatable, ConfirmDeleteDialog},
    created() {
        this.entity = new PaymentRequestInvoicePay(this.session);
    },
    async mounted() {
        await this.refresh();
        this.loading = true;
        //Cargamos la sucursal
        this.branch.id = this.session.branch;
        this.branch = fillObject(this.branch, await this.branch.retrieve());
        (this.loading = false);
    },
    watch: {},
    methods: {
         confirmDelete(entity) {
            this.detailDelete = fillObject(this.detailDelete, entity);
            this.deleteDialog = true;
        },
     deleteDetail() {
            let id = this.detailDelete.id;
            this.entity.payments_details = this.entity.payments_details.filter(x => x.id != this.detailDelete.id);
     this.entity.payments_details.forEach(detail => {
        console.log("id: " + detail.id)
                if (detail.id > id) {
                    detail.id = detail.id - 1;
                    this.entity.id = detail.id;
                    this.entity.delete();
                }
            });
            this.deleteDialog = false;
        },
        async selectPaymentRequest(paymentrequest) {
            try {

                this.entity.id_payment_request = paymentrequest.id;
                let retrieved = await this.entity.payment();

                this.entity.id_payment_request = retrieved[0].id;
                this.entity.payment_request_reference = retrieved[0].concept_name;
                this.entity.payment_request_date = retrieved[0].date;
                if (this.entity.payment_request_date) this.entity.payment_request_date = new Date(this.entity.payment_request_date);
                this.entity.payment_request_supplier = retrieved[0].supplier_name;
                this.entity.id_bank_account = retrieved[0].id_account;
                this.entity.payment_request_key_name_account = retrieved[0].key_account;
                this.entity.payment_request_exchange_rate = retrieved[0].exchange_rate;
                this.entity.payment_request_total = retrieved[0].total;
                this.entity.payment_request_reconciled_total = retrieved[0].reconcilied_total;
                this.entity.payment_request_not_conciled = retrieved[0].not_reconcilied;

                //* Llenamos los conceptos
                 this.entity.payments_details = await this.entity.payments();
                
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
          async selectInvoicePay(invoicepay) {
            try {

                this.entity.id_invoice_pay = invoicepay.id;
                let retrieved = await this.entity.invoice();

                this.entity.id_invoice_pay = retrieved[0].id;
                this.entity.invoice_supplier = retrieved[0].supplier_name;
                this.entity.invoice_date = retrieved[0].date;
                if (this.entity.invoice_date) this.entity.invoice_date = new Date(this.entity.invoice_date);
                this.entity.exchange_rate = retrieved[0].exchange_rate;
                this.entity.invoice_total = retrieved[0].total;
                this.entity.invoice_reconciled_total = retrieved[0].reconciled_total;
                this.entity.invoice_not_conciled = retrieved[0].not_reconcilied;
                this.entity.status_invoice_pay = retrieved[0].id_estatus;

                if(this.entity.exchange_rate > 1)
                    this.entity.currency = 'DLLS';
                else
                     this.entity.currency = 'MXN';
                
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        hideDialog() {
            this.newDialog = false;
        },
        async save() {
         try {
            console.log("entre");
                //* Validacion de form
                this.loading = true;
                
                  let response = await this.entity.post();
                    this.entity.id = response.id;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Guardar',
                        detail: 'Informacion guardada con exito',
                        life: 3000,
                    });

                 //* Llenamos los conceptos
                 this.entity.payments_details = await this.entity.payments();
             } catch (error) {
               this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    
        async refresh() {
            try {
                this.entities = await this.entity.all();
                this.entity.reconciliation_policy_date = new Date();
                this.paymentRequests = await new PaymentRequest(this.session).all();
                this.invoicePays = await new DTPInvoice(this.session).all();
               
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
         async openNew() {
            try {
                this.loading = true;
                this.entity = new PaymentRequestInvoicePay(this.session);
                this.branch.id = this.session.branch;
                this.branch = fillObject(this.branch, await this.branch.retrieve());
                this.newDialog = true;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
